<template>
  <div class="d-flex">
    <app-button @click="refreshDetail()" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
    <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button>

    <app-log-data-dialog module_name="mjo" :id="mjo_header.id">
      <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
    </app-log-data-dialog>

    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="($route.name == 'Administration.MJO.Detail') ? true : false" @click="redirect('Administration.MJO.Detail', { id: mjo_header.id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
    <app-button :disabled="!enableEditBtn" @click="redirect('Administration.MJO.Edit', { id: mjo_header.id })" title="Edit" mdi-icon="mdi-pencil"></app-button>
    <app-button :disabled="!enableUpdateBtn" @click="redirect('Administration.MJO.Update', { id: mjo_header.id })" title="Update" mdi-icon="mdi-file-document-edit-outline"></app-button>
    <app-button :disabled="!enableDeleteBtn" @click="deleteData()" :loading="loading_delete" title="Delete Document" mdi-icon="mdi-delete"></app-button>
    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableSendApprovalBtn" @click="sendApproval()" :loading="loading_send_approval" title="Send Approval" mdi-icon="mdi-file-send"></app-button>
    <app-button :disabled="!enableApproveBtn" @click="approveData()" :loading="loading_approve" title="Approve Document" mdi-icon="mdi-file-check"></app-button>
    <app-button :disabled="!enableRejectBtn" @click="rejectData()" :loading="loading_reject" title="Reject Document" mdi-icon="mdi-file-remove" color="red"></app-button>
    <v-divider vertical class="mx-2"></v-divider>
    <app-button :disabled="!enableReceiveBtn" @click="showReceiveDialog()" :loading="loading_receive" title="Receive Document" mdi-icon="mdi-checkbox-marked-outline"></app-button>
    <app-button :disabled="!enableCancelBtn" @click="cancelData()" :loading="loading_cancel" title="Cancel Document" mdi-icon="mdi-file-document-remove"></app-button>
    <app-button :disabled="!enableReopenBtn" @click="reopenData()" :loading="loading_reopen" title="Re-Open Document" mdi-icon="mdi-file-restore"></app-button>
    <app-button :disabled="!enableCloseBtn" @click="closeData()" :loading="loading_close" title="Closed Document" mdi-icon="mdi-book-check"></app-button>


    <app-dialog v-model="receive_dialog" max-width="600px" title="Receive Document">
      <template #toolbar>
        <app-button @click="receiveData()" color="primary" :loading="loading_receive" title="Receive Document" label="Receive" mdi-icon="mdi-checkbox-marked-outline"></app-button>
        <app-button @click="refuseData()" color="warning" :loading="loading_refuse" title="Refuse Document" label="Refuse" mdi-icon="mdi-alpha-x-box-outline"></app-button>
      </template>
      <v-card>
        <v-card-text>
          <v-textarea v-model="refuse_reason" outlined hide-details label="Reason"></v-textarea>
        </v-card-text>
      </v-card>
    </app-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dataDetail: {
      type: Object,
      default: null
    },
  },
  data(){
    return {
      loading: false,
      loading_delete: false,
      loading_send_approval: false,
      loading_reopen: false,
      loading_close: false,
      loading_approve: false,
      loading_receive: false,
      loading_cancel: false,
      loading_attachment: false,
      loading_reject: false,
      loading_refuse: false,
      data_detail: {},

      receive_dialog: null,
      refuse_reason: "",
    }
  },
  watch: {
    dataDetail(){
      this.data_detail = this.dataDetail;
    }
  },
  computed: {
    mjo_header() {
      return (typeof this.dataDetail.mjo_header != 'undefined') ? this.dataDetail.mjo_header : {};
    },
    mjo_approval() {
      return (typeof this.dataDetail.mjo_approval != 'undefined') ? this.dataDetail.mjo_approval : {};
    },
    enableDeleteBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "approved"])) return false;

      if (this.mjo_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("mjo", "delete") && !this.inArray(this.mjo_header.status, ["approved"]) && this.inArray(this.mjo_header.status, ["open", "cancel"])) {
        return true;
      }
      return false;
    },
    enableReopenBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel", "open"])) return false;

      if (this.mjo_header.created_by_username !== this.$store.state.loginInfo.username) return false;

      if (this.modulePermission("mjo", "reopen") && !this.inArray(this.mjo_header.status, ["open"])) {
        return true;
      }
      return false;
    },
    enableCancelBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "cancel") && !this.inArray(this.mjo_header.status, ["approved"]) && !this.inArray(this.mjo_header.status, ["cancel"])) {
        return true;
      }
      return false;
    },
    enableSendApprovalBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "send-approval") && this.mjo_header.status == "open") {
        return true;
      }
      return false;
    },
    enableApproveBtn() {
      if (!this.inArray(this.mjo_header.status, ["waiting-approval"])) return false;

      if (this.mjo_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.mjo_approval !== "undefined") {
          if (this.mjo_approval.length >= 0) {
            this.mjo_approval.forEach((item) => {
              let approved = item.approved
              if (!approved) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    enableRejectBtn() {
      if (!this.inArray(this.mjo_header.status, ["waiting-approval"])) return false;

      if (this.mjo_header.status == "waiting-approval") {
        let ap = false;
        if (typeof this.mjo_approval !== "undefined") {
          if (this.mjo_approval.length >= 0) {
            this.mjo_approval.forEach((item) => {
              let approved = item.approved
              if (!approved) {
                if (item.username == this.$store.state.loginInfo.username) {
                  ap = true;
                }
              }
            });
          }
        }

        return ap;
      }
      return false;
    },
    enableEditBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "modify") && this.mjo_header.status == "open") {
        return true;
      }
      return false;
    },
    enableUpdateBtn() {
      if (this.inArray(this.mjo_header.status, ["closed", "cancel"])) return false;

      if (this.modulePermission("mjo", "update") && this.mjo_header.status == "approved" && (this.mjo_header.substatus != "" && this.mjo_header.substatus != null)) {
        return true;
      }
      return false;
    },
    enableCloseBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (this.modulePermission("mjo", "close")) {
        return true;
      }

      return false;
    },
    enableSetPicBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (this.modulePermission("mjo", "set-pic") && this.mjo_header.status == "approved") {
        return true;
      }
      return false;
    },
    enableReceiveBtn() {
      if (!this.inArray(this.mjo_header.status, ["approved"])) return false;

      if (this.modulePermission("mjo", "receive") && this.mjo_header.received == false) {
        return true;
      }

      return false;
    },
  },
  methods: {

    refreshDetail(){
      this.$emit('refresh-detail')
    },

    /**
     * deleteData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Cancel/Open
     */
    deleteData() {
      let post = async () => {
        this.loading_delete = true;
        this.showLoadingOverlay(true);
        await this.$axios
          .delete("mjo/delete/" + this.mjo_header.id)
          .then((res) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_delete = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Delete document?", post);
    },

    /**
     * cancelData
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    cancelData() {
      let post = async () => {
        this.loading_cancel = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/cancel", formData)
          .then((res) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_cancel = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Cancel document?", post);
    },

    /**
     * sendApproval
     * Method untuk menghapus dokumen
     * Dokumen harus berstatus Open
     */
    sendApproval() {
      let post = async () => {
        this.loading_send_approval = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/send-approval", formData)
          .then((res) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_send_approval = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Send Approval?", post);
    },

    /**
     * approveData
     * Method untuk meng-approved dokumen
     * Dokumen harus berstatus Waiting Approval
     */
    approveData() {
      let post = async () => {
        this.loading_approve = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/approve", formData)
          .then((res) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_approve = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Approve document?", post);
    },

    rejectData() {
      let post = async () => {
        this.loading_reject = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/reject", formData)
          .then((res) => {
            this.loading_reject = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reject = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };
      this.showConfirm("Confirm", "Reject document?", post);
    },

    /**
     * reopenData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    reopenData() {
      let post = async () => {
        this.loading_reopen = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/reopen", formData)
          .then((res) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_reopen = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Re-open document?", post);
    },

    /**
     * closeData
     * Method untuk re-open dokumen
     * Dokumen di kembalikan ke status Open
     */
    closeData() {
      let post = async () => {
        this.loading_close = true;
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/close", formData)
          .then((res) => {
            this.loading_close = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_close = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      };

      this.showConfirm("Confirm", "Close document?", post);
    },

    showReceiveDialog(){
      this.receive_dialog = true;
      this.refuse_reason = "";
    },

    hideReceiveDialog() {
      this.receive_dialog = false;
      this.refuse_reason = "";
    },

    receiveData() {
      let post = async () => {
        this.loading_receive = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        await this.$axios
          .post("mjo/receive", formData)
          .then((res) => {
            this.loading_receive = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);

            this.hideReceiveDialog();
            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_receive = false;
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Receive document?", post);
    },

    refuseData() {

      let post = async () => {
        this.loading_refuse = true;
        this.showLoadingOverlay(true);
        const formData = new FormData();
        formData.append("id", this.mjo_header.id);
        formData.append("reason", this.refuse_reason);
        await this.$axios
          .post("mjo/refuse", formData)
          .then((res) => {
            this.loading_refuse = false;
            this.showLoadingOverlay(false);
            this.showAlert(res.data.status, res.data.message);
            this.hideReceiveDialog();
            this.refreshDetail();
          })
          .catch((error) => {
            this.loading_refuse = false;
            this.showLoadingOverlay(false);
            this.hideReceiveDialog();
            this.axiosErrorHandler(error);
          });
      }
      this.showConfirm("Confirm", "Refuse document?", post);
    },
  }
}
</script>